import Button from "@mui/material/Button";
import { ReactNode, createContext, useContext, useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

import appCfg from "../env";
const { url, clientId, scopes, authCallbackUrl, refreshUrl } = appCfg.oidc;

export interface AuthData {
  idToken:
    | {
        expiry: Date;
        raw: string;
        claims: Record<string, any>;
      }
    | undefined;
  authTokenExpiry: Date | undefined;
}

const AuthContext = createContext<AuthData>({
  idToken: undefined,
  authTokenExpiry: undefined,
});

const isSignedIn = (auth: AuthData) => auth.authTokenExpiry && auth.authTokenExpiry.getTime() > Date.now();

function authenticate() {
  window.location.href = `${url}/v1/authorize?client_id=${encodeURIComponent(clientId)}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(authCallbackUrl)}&response_type=code&state=${encodeURIComponent(window.location.href)}`;
}

function SignedOut() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>Access to this application requires authorisation.</div>
      <Button onClick={authenticate}>Sign In</Button>
    </div>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthenticatedApplication(props: { children: ReactNode }) {
  const [storedIdToken, setStoredIdToken, removeStoredIdToken] = useLocalStorage<string | undefined>("hudata_idtoken", undefined);
  const [storedExp, setStoredExp, removeStoredExp] = useLocalStorage<number | undefined>("hudata_exp", undefined);

  const auth: AuthData = useMemo(() => {
    const expiry = storedExp !== undefined ? new Date(storedExp * 1000) : undefined;
    return {
      idToken:
        storedIdToken && expiry
          ? {
              raw: storedIdToken,
              claims: JSON.parse(atob(storedIdToken.split(".")[1])) as Record<string, any>,
              expiry,
            }
          : undefined,
      authTokenExpiry: expiry,
    };
  }, [storedExp, storedIdToken]);

  useEffect(() => {
    async function renewAccessToken() {
      console.log("Renewing Access token");
      const res = await fetch(refreshUrl, {
        credentials: "include",
        cache: "no-cache",
      });
      if (res.ok) {
        const resJson = await res.json();
        setStoredExp(parseInt(resJson.expiry));
        console.log("Udpated Stored Token expiry", resJson);
      } else {
        // We couldn't renew the token, so sign the user out.
        removeStoredExp();
        removeStoredIdToken();
      }
    }

    let refreshTimer: any = undefined;
    if (storedExp) {
      // Rnew 5 minutes before expiry.
      const timeToRenew = storedExp * 1000 - 5 * 60 * 1000 - Date.now();
      console.log("Renew timer in", timeToRenew);
      if (timeToRenew > 0) {
        refreshTimer = setTimeout(renewAccessToken, timeToRenew);
      }
    }

    return () => {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, [storedExp, removeStoredExp, removeStoredIdToken, setStoredExp]);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const new_idtoken = searchParams.get("hudata_id");
    const new_exp = searchParams.get("hudata_exp");

    if (new_idtoken && new_exp) {
      setStoredIdToken(new_idtoken);
      setStoredExp(parseInt(new_exp));
      const newSearch = new URLSearchParams(searchParams);
      newSearch.delete("hudata_id");
      newSearch.delete("hudata_exp");
      document.location.search = newSearch.toString();
    }
  }, [setStoredExp, setStoredIdToken]);

  console.log("Auth expires at", auth.authTokenExpiry);
  if (!isSignedIn(auth)) {
    return <SignedOut />;
  }

  return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
}
