import Typography from "@mui/material/Typography";

export default function Timestamp(props: { ts: Date }) {
  return (
    <Typography variant="inherit">
      {Intl.DateTimeFormat(undefined, {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(props.ts)}
    </Typography>
  );
}
