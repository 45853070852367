import { SchemaObject } from "ajv";
import { FlatDataItem } from "./typedef";

export * from "./typedef";
export * from "./validator";
export * from "./DataInterface";

export function stripMeta(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => !k.startsWith("_")));
}
