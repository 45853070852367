import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import env from "../env";

interface User {
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phone?: string;
}

interface EmailEditorProps {
  val: string;
  onChange: (val: string) => any;
  onCommit?: (val: string) => any;
  clearOnCommit?: boolean;
  error?: string | undefined;
  label?: string | undefined;
  placeholder?: string;
}

interface NonLookupValue {
  nonlookup: string;
}

function isNonLookup(x: User | NonLookupValue): x is NonLookupValue {
  return (x as any).nonlookup !== undefined;
}

export default function EmailEditor(props: EmailEditorProps) {
  const { val, error, label } = props;
  const [options, setOptions] = useState<(User | NonLookupValue)[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (val) {
      const abort = new AbortController();
      async function fetchOptions() {
        try {
          const res = await fetch(`https://${env.api.endpoint}/users?q=${encodeURIComponent(val)}`, {
            signal: abort.signal,
            mode: "cors",
            credentials: "include",
            cache: "no-cache",
          });
          if (res.ok && !abort.signal.aborted) {
            const returnedOptions = (await res.json()) as User[];
            if (!abort.signal.aborted) {
              setOptions([...returnedOptions, { nonlookup: val }]);
            }
          }
        } catch (ex: any) {
          if (!ex.message.startsWith("signal is aborted")) {
            throw ex;
          }
        } finally {
          setLoading(false);
        }
      }
      setOptions([{ nonlookup: val }]);
      setLoading(true);
      const timer = setTimeout(fetchOptions, 200);
      return () => {
        clearTimeout(timer);
        abort.abort();
      };
    } else {
      setOptions([]);
    }
  }, [val]);

  return (
    <Autocomplete
      disablePortal
      options={options}
      freeSolo
      selectOnFocus
      clearOnBlur
      loading={loading}
      autoHighlight
      // disableClearable
      sx={{ width: 350 }}
      value={val ?? ""}
      onChange={(evt, newValue) => {
        if (props.onCommit) {
          if (newValue == null) {
          } else if (typeof newValue == "string") {
          } else if (isNonLookup(newValue)) {
            props.onCommit(newValue.nonlookup!);
          } else if (newValue.email) {
            props.onCommit(newValue.email);
          }
        }
      }}
      filterOptions={(options) => {
        return options.filter((o) => isNonLookup(o) || val.indexOf(o.email) === -1);
      }}
      onSelect={(evt) => {
        props.onChange((evt.target as any).value);
      }}
      getOptionLabel={(option) => {
        // for example value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (isNonLookup(option)) {
          return option.nonlookup;
        }
        return option.email;
      }}
      renderInput={(params) => (
        <TextField
          placeholder={props.placeholder}
          variant="standard"
          {...params}
          error={error !== undefined}
          label={label}
          helperText={error ?? ""}
        />
      )}
    />
  );
}
