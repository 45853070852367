import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import { ReactNode } from "react";
import { DataItemReference } from "@mechination/data_validation";
import { useNavigate, useParams } from "react-router-dom";

function insertCommas(x: ReactNode[]) {
  const out: ReactNode[] = [];
  let first = true;

  x.forEach((i, idx) => {
    if (first) {
      first = false;
    } else {
      out.push(<Typography key={`_comma+${idx}`}>, </Typography>);
    }
    out.push(i);
  });
  return out;
}

export default function Summary(props: { obj: any; schema: any }) {
  const { obj, schema } = props;
  const navigate = useNavigate();
  const { domainId } = useParams();

  function linkClicked(link: DataItemReference) {
    navigate(`/app/${domainId}/types/${link.type}/items/${link.id}`);
  }

  let sval: string | undefined;
  if (obj === undefined) {
    return (
      <Typography color="GrayText" variant="subtitle2">
        &lt;none&gt;
      </Typography>
    );
  }
  if (typeof obj === "string") {
    sval = obj;
  } else if (typeof obj === "object") {
    if (schema.type === "array") {
      let items = obj as any[];
      let views = items.slice(0, 3).map((i, idx) => <Summary key={idx} obj={i} schema={schema.items} />);
      if (items.length > 3) {
        views.push(<Typography key={-1}>&hellip;[{items.length - 3}]</Typography>);
      }
      return (
        <Box display="flex" flexDirection={"row"}>
          {insertCommas(views)}
        </Box>
      );
    }
    if (schema.$ref) {
      if (schema.$ref === "/schemas/monetary_value") {
        sval = `$${obj.value}`;
      } else if (schema.$ref === "/schemas/itemref") {
        return <Chip label={obj.id} onDelete={() => linkClicked(obj)} deleteIcon={<LinkIcon />} />;
        // } else if (typeof obj.name === "string") {
        //   sval = obj.name;
      }
    } else if (schema.properties) {
      return (
        <Box display="flex" flexDirection="row" gap={1}>
          {Object.entries(schema.properties).map(([prop, subschema]) => {
            return (
              <Box display="flex" flexDirection="column" padding={0.5}>
                <Typography variant="caption">{prop}</Typography>
                <Summary obj={obj[prop]} schema={subschema} />
              </Box>
            );
          })}
        </Box>
      );
    }
  }
  return <Typography>{sval ?? JSON.stringify(obj)}</Typography>;
}
