import Ajv, { AnySchemaObject } from "ajv";
import addFormats from "ajv-formats";
import { DataItemType, FlatDataItem, typeSchema } from "./typedef";
import { evaluateExpr } from "@mechination/data_expr";

export interface ValidationError {
  instancePath: string;
  schemaPath?: string;
  message: string;
}

export type ValidatorFunction = (item: Record<string, unknown>, userClaims: any) => ValidationError[];

export class DataItemValidator {
  ajv: Ajv;
  baseURL: string;

  constructor(baseURL: string, loadSchema?: (uri: string) => Promise<AnySchemaObject>) {
    this.baseURL = baseURL;
    this.ajv = new Ajv({ inlineRefs: true, dynamicRef: true, loadSchema: loadSchema ?? this.defaultLoadSchema, allErrors: true });
    addFormats(this.ajv);
    this.ajv.addFormat("multiline", true); // Add a marker format that allows the editor to distinguish between a simple string and a multiline one.
    this.ajv.addFormat("expression", true); // Add a marker format that allows the editor to realise this is a validation or other expression.
    this.ajv.addFormat("schema", true); // Add a marker format that allows the editor to realise this is JSONSchema schema
    this.ajv.addFormat("image", true); // A marker that says that an attachment is an image

    this.ajv.addKeyword({
      keyword: "allowedType",
      metaSchema: {
        oneOf: [{ type: "string" }, { type: "array", items: { type: "string", minLength: 1 }, minItems: 1 }],
      },
    });
  }

  async defaultLoadSchema(uri: string): Promise<AnySchemaObject> {
    const res = await fetch(uri);
    if (!res.ok) {
      throw new Error("Could not load schema from uri " + uri);
    }
    const schemaJson = await res.json();
    return schemaJson;
  }

  getValidatorId(domain: string, type: string) {
    return `${this.baseURL}/schema/${domain}/${type}`;
  }

  async createValidator(type: FlatDataItem<DataItemType>): Promise<ValidatorFunction> {
    const id = this.getValidatorId(type._domain, type._id);
    const schemaJson = JSON.parse(type.schema);
    this.ajv.removeSchema(id);
    schemaJson.$id = id; // Override one if its there.

    const schemaValidator = await this.ajv.compileAsync<unknown>(schemaJson);
    const newValidator = (item: Record<string, unknown>, userClaims: any): ValidationError[] => {
      const valid = schemaValidator(item);

      if (!valid) {
        return schemaValidator.errors!.map((e) => {
          let instancePath = e.instancePath;
          if (e.propertyName) {
            instancePath += `/${e.propertyName}`;
          }
          if (e.keyword == "additionalProperties" && e.params.additionalProperty) {
            instancePath += `/${e.params.additionalProperty}`;
          }

          return {
            instancePath,
            schemaPath: e.schemaPath,
            message: e.message,
          };
        });
      }

      // It passed schema validation.  Also make sure it passes aditional validation.
      if (!type.validation) {
        return [];
      }

      return type.validation
        .map((v) =>
          !evaluateExpr(v.expr, { ...item, _user: userClaims })
            ? { instancePath: "#", schemaPath: "#", message: v.message ?? `Failed to pass validation expression ${v.expr}` }
            : undefined
        )
        .filter((e) => e !== undefined);
    };
    return newValidator;
  }
}
