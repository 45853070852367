import { DataItemDomain, DataItemList } from "@mechination/data_validation";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useRouteLoaderData } from "react-router-dom";
import ObjectIcon from "../components/ObjectIcon";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function DomainList() {
  const domains = useRouteLoaderData("domainList") as DataItemList<DataItemDomain>;

  const items = domains.map((d) => (
    <ListItemButton key={d._id} href={`/app/${d._id}`}>
      <ListItemText
        sx={{ alignItems: "center", display: "flex", flexDirection: "row", gap: "0.25em", marginLeft: "0.2em" }}
        disableTypography
      >
        <ObjectIcon item={d} />
        {d.name ?? d._id}
      </ListItemText>
    </ListItemButton>
  ));

  return (
    <Container>
      <Paper sx={{ mt: 4, p: 2 }}>
        <Stack>
          <Typography variant="h3">HuData</Typography>
          <Typography component="p">This application manages multiple data domians.</Typography>
          <Typography component="p" marginTop={2}>
            Please choose a domain to work with.
          </Typography>
          <List>{items}</List>
          <Typography component="p" marginTop={5} variant="caption">
            Create a new data domain in the admin domain, if you have access to it
          </Typography>
        </Stack>
      </Paper>
    </Container>
  );
}
