import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemsInType from "./pages/ListItemsInType";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useRouteError, Navigate } from "react-router-dom";
import ItemEditor from "./pages/ItemEditor";
import Index from "./pages/Index";
import Alert from "@mui/material/Alert";
import NavAndContent from "./layouts/NavAndContent";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DataAPI } from "@mechination/data_client";
import AuthenticatedApplication from "./providers/AuthenticatedApplication";
import DomainList from "./pages/DomainList";
import env from "./env";
import Button from "@mui/material/Button";
import { useLocalStorage } from "usehooks-ts";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function ErrorRenderer() {
  const err = useRouteError() as any;
  const removeStoredExp = useLocalStorage<number | undefined>("hudata_exp", undefined)[2];

  let msg: string;

  if (typeof err === "object" && err?.data) {
    msg = err.data;
  } else {
    msg = err.toString();
    if (msg.startsWith("Error: ")) {
      msg = msg.substring(7);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Alert severity="error">Error: {msg}</Alert>
      <Button
        onClick={() => {
          removeStoredExp();
        }}
      >
        Sign out
      </Button>
    </div>
  );
}

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const api = new DataAPI(env.api.endpoint);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        id="domainList"
        errorElement={<ErrorRenderer />}
        element={<NavAndContent />}
        loader={(x) => api.listItems("admin", "domain")}
      >
        <Route index element={<Navigate replace to="/app" />} />
        <Route path="/app" element={<DomainList />} />
        <Route path="/app/:domainId" id="domain" loader={(x) => api.getDomain(x.params.domainId!)}>
          <Route index element={<Index />} />
          <Route path="/app/:domainId/types/:type" id="type" loader={(x) => api.getType(x.params.domainId!, x.params.type!)}>
            <Route
              path="/app/:domainId/types/:type/items"
              element={<ListItemsInType />}
              loader={(x) => api.listItems(x.params.domainId!, x.params.type!)}
            />
            <Route path="/app/:domainId/types/:type/new" element={<ItemEditor />} />
            <Route
              path="/app/:domainId/types/:type/items/:id"
              element={<ItemEditor />}
              loader={(x) => api.getItem(x.params.domainId!, x.params.type!, x.params.id!)}
            />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <AuthenticatedApplication>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthenticatedApplication>
  );
}

root.render(<App />);

// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
