import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import env from "../env";
import { DataAPI } from "@mechination/data_client";
import { Box, Button, Link, Tab, Tabs, Typography } from "@mui/material";
import { FlatDataItem } from "@mechination/data_validation";

interface ItemRefrencesProps {
  type: string;
  domain: string;
  itemId: string;
}

const api = new DataAPI(env.api.endpoint);

export default function ItemReferences(props: ItemRefrencesProps) {
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [linkedItems, setLinkedItems] = useState<Record<string, FlatDataItem<any>[]>>({});
  const [selectedRefType, setSelectedRefType] = useState<number>(0);

  const loadReferences = useCallback(async () => {
    setHistoryLoading(true);
    try {
      const refs = await api.getItemReferences(props.domain, props.type, props.itemId);
      const byType: Record<string, FlatDataItem<any>[]> = {};
      for (let i of refs) {
        let items = byType[i._type];
        if (!items) {
          byType[i._type] = [i];
        } else {
          items.push(i);
        }
      }
      setLinkedItems(byType);
      setSelectedRefType(0);
    } finally {
      setHistoryLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadReferences();
  }, [loadReferences]);

  const allTypes = Object.keys(linkedItems);

  return (
    <AccordionDetails>
      {historyLoading ? (
        <CircularProgress />
      ) : allTypes.length === 0 ? (
        <Typography variant="caption">
          No other data items link to this one. View data above to see which items this one links to
        </Typography>
      ) : (
        <div style={{ maxWidth: "100%", overflow: "hidden" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedRefType}
              onChange={(evt, newVal) => {
                setSelectedRefType(newVal);
              }}
            >
              {allTypes.map((type) => {
                return <Tab label={type} key={type} />;
              })}
            </Tabs>
          </Box>

          <div role="tabpanel">
            <ol>
              {(Object.values(linkedItems)[selectedRefType] ?? []).map((item) => (
                <li key={item._id}>
                  <Link
                    component={RouterLink}
                    to={`/app/${encodeURIComponent(item._domain)}/types/${encodeURIComponent(item._type)}/items/${encodeURIComponent(item._id)}`}
                  >
                    {item.name ?? item._id}
                  </Link>{" "}
                  -{" "}
                  <Typography variant="body2" color="GrayText" component={"span"} fontSize={"60%"}>
                    {item._path}
                  </Typography>
                </li>
              ))}
            </ol>
          </div>

          {historyLoading ? (
            <CircularProgress />
          ) : (
            linkedItems.next && <Button onClick={() => loadReferences()}>fetch older events</Button>
          )}
        </div>
      )}
    </AccordionDetails>
  );
}
