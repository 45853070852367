import { DataAPI } from "@mechination/data_client";
import { DataItemReference, FlatDataItem } from "@mechination/data_validation";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactNode, useEffect, useState } from "react";
import ListItemText from "@mui/material/ListItemText";
import ObjectIcon from "./ObjectIcon";
import env from "../env";

export interface ItemRef {
  id: string;
  type: string;
}

interface ItemSelectorProps {
  value: ItemRef | undefined;
  types?: undefined | string[];
  name: string;
  onChange: (newRef: DataItemReference) => any;
}

function strToRef(s: string): DataItemReference {
  const bits = s.split("#");
  if (bits.length !== 2) {
    throw new Error("Invalid Ref Str");
  }
  return {
    type: bits[0],
    id: bits[1],
  };
}

export default function ItemSelector(props: ItemSelectorProps) {
  const [possibleValues, setPossibleValues] = useState<ReactNode[]>([]);

  useEffect(() => {
    const api = new DataAPI(env.api.endpoint);
    if (props.types) {
      // TODO only project names, images, and IDs - fall back to ID if name not specified (could also consult the type itself)
      Promise.all(props.types?.map((t) => api.listItems("catering", t)))
        .then((itemsFromTypes) =>
          itemsFromTypes.length === 1 ? itemsFromTypes[0] : ([] as FlatDataItem<any>[]).concat.apply([], itemsFromTypes)
        )
        .then((items) => {
          return setPossibleValues(
            items.map((i) => {
              const key = `${i._type}#${i._id}`;
              return (
                <MenuItem value={key} key={key}>
                  <ListItemText
                    sx={{ alignItems: "center", display: "flex", flexDirection: "row", gap: "0.25em", marginLeft: "0.2em" }}
                    disableTypography
                  >
                    <ObjectIcon item={i} />
                    {i.name ?? i._id}
                  </ListItemText>
                </MenuItem>
              );
            })
          );
        });
    } else {
      // is link to _any_ item!.   Make them specify.
      setPossibleValues([]);
    }
  }, [props.types]);

  return (
    <FormControl variant="standard" sx={{ minWidth: 120 }}>
      {props.name && <InputLabel id="demo-simple-select-standard-label">{props.name}</InputLabel>}
      <Select
        label={props.name}
        variant="standard"
        value={`${props.value?.type}#${props.value?.id}`}
        onChange={(evt) => props.onChange(strToRef(evt.target.value))}
      >
        {possibleValues}
      </Select>
    </FormControl>
  );
}
