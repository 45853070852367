declare global {
  interface Window {
    HUDATA_CONFIG: ApplicationConfig;
  }
}

export interface OidcConfig {
  clientId: string;
  scopes: string;
  authCallbackUrl: string;
  refreshUrl: string;
  url: string;
}

export interface ApiConfig {
  endpoint: string;
}

export interface ApplicationConfig {
  api: ApiConfig;
  oidc: OidcConfig;
}

const config: ApplicationConfig = window.HUDATA_CONFIG;
export default config;
