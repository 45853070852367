import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useMemo } from "react";
import { isInError } from "../utils/errors";
import EmailEditor from "../components/EmailEditor";

interface StringEditorProps extends SchemaDefinedEditorProps {
  width?: string | number;
  multiline?: boolean;
}

export default function EmailSchemaEditor(props: StringEditorProps) {
  const { val, errors, path } = props;
  const error = useMemo(() => isInError(path, errors), [errors, path]);

  return <EmailEditor val={val} error={error} onChange={props.onChange} label={props.name} />;
}
