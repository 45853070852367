import { search, compile, registerFunction, TYPE_STRING, JSONValue } from "@metrichor/jmespath";
import ShortUniqueId from "short-unique-id";

const shortUuidGen = new ShortUniqueId({
  length: 12,
});

// Register some standard functions that we can use.
registerFunction("uuid", () => shortUuidGen.rnd(), []);
registerFunction("lower", (args) => (args[0] as string).toLowerCase(), [{ types: [TYPE_STRING] }]);
registerFunction("regex_replace", (args) => (args[0] as string).replace(new RegExp(args[1], args[3]), args[2]), [
  { types: [TYPE_STRING] },
  { types: [TYPE_STRING] },
  { types: [TYPE_STRING] },
  { types: [TYPE_STRING], optional: true },
]);

export function evaluateExpr(expr: string, src: JSONValue) {
  return search(src, expr);
}
