import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Delete";
import AllIcon from "@mui/icons-material/Checklist";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataItemAclAction, DataItemAclEntry, DataItemAclOutcome } from "@mechination/data_validation";
import { StringArrayEditor } from "../components/StringArrayEditor";
import { PersonListEditor } from "../components/PersonListEditor";
import { isInError } from "../utils/errors";

interface ActionSetEditorProps {
  actions: DataItemAclAction[] | undefined;
  onChange: (newVals: DataItemAclAction[] | undefined) => any;
}

function ActionSetEditor(props: ActionSetEditorProps) {
  const allActions: DataItemAclAction[] = ["create", "read", "modify", "delete", "list"];

  function setAction(name: DataItemAclAction, val: boolean) {
    const newActions = new Set(props.actions ?? allActions);
    if (val) {
      newActions.add(name);
    } else {
      newActions.delete(name);
    }
    const asArray = Array.from(newActions);
    if (asArray.length !== 0) {
      props.onChange(asArray.length === allActions.length ? undefined : asArray);
    }
  }

  return (
    <div>
      {allActions.map((a) => (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={(evt) => setAction(a, evt.target.checked)}
              checked={!props.actions || props.actions.indexOf(a) !== -1}
            />
          }
          label={a}
        />
      ))}
      <Button
        startIcon={<AllIcon />}
        size="small"
        sx={{ padding: 0, margin: 0 }}
        disabled={!props.actions}
        onClick={() => props.onChange(undefined)}
      >
        select all
      </Button>
    </div>
  );
}

interface OutcomeEditorProps {
  outcome: DataItemAclOutcome | undefined;
  onChange: (newAction: DataItemAclOutcome) => any;
}

function OutcomeEditor(props: OutcomeEditorProps) {
  const outcome = props.outcome;
  return (
    <Chip
      color={outcome === "deny" ? "warning" : "success"}
      size="small"
      label={outcome ?? "allow"}
      onClick={(evt) => props.onChange(outcome === "deny" ? "allow" : "deny")}
    />
  );
}

interface AclRowEditorProps extends SchemaDefinedEditorProps {
  val: DataItemAclEntry;
}

function AclRowEditor(props: AclRowEditorProps) {
  const { val: entry } = props;

  const principalsPath = `${props.path}.principals`;
  const principalErrorIndexes = entry.principals
    .map((p, idx) => (isInError(`${principalsPath}.${idx}`, props.errors) ? idx : -1))
    .filter((idx) => idx !== -1);

  const inError = isInError(props.path, props.errors);

  return (
    <TableRow sx={(theme) => (inError ? { borderColor: theme.palette.error.dark, borderWidth: 1, borderStyle: "solid" } : {})}>
      <TableCell>
        <Checkbox />
      </TableCell>

      <TableCell align="center">
        <OutcomeEditor outcome={entry.outcome} onChange={(outcome) => props.onChange({ ...entry, outcome })} />
      </TableCell>
      <TableCell>
        <PersonListEditor
          value={entry.principals}
          invalidEntries={principalErrorIndexes}
          onChange={(people) => props.onChange({ ...entry, principals: people })}
        />
      </TableCell>
      <TableCell>
        <ActionSetEditor actions={entry.actions} onChange={(actions) => props.onChange({ ...entry, actions })} />
      </TableCell>
      <TableCell>
        <StringArrayEditor
          value={entry.conditions ?? []}
          onChange={(newVals) => props.onChange({ ...entry, conditions: newVals.length === 0 ? undefined : newVals })}
        />
      </TableCell>
    </TableRow>
  );
}

export default function AclEditor(props: SchemaDefinedEditorProps) {
  let rows = props.val;
  if (!Array.isArray(rows)) {
    rows = [];
  }

  function rowChanged(row: number, newEntry: DataItemAclEntry) {
    const newAcl = [rows];
    newAcl[row] = newEntry;
    props.onChange(newAcl);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5} sx={{ padding: "0 0 0 1em", margin: 0 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Typography color="GrayText" fontSize="80%">
                      {props.name}
                    </Typography>
                    <Typography variant="subtitle1">{props.description}</Typography>
                  </div>

                  <IconButton>
                    <AddIcon />
                  </IconButton>
                  <IconButton>
                    <RemoveIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" width={60}>
                outcome
              </TableCell>
              <TableCell align="center" width={350}>
                principals
              </TableCell>
              <TableCell align="center" width={250}>
                actions
              </TableCell>
              <TableCell align="center">conditions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: DataItemAclEntry, idx: number) => (
              <AclRowEditor
                {...props}
                val={row}
                path={`${props.path}.${idx}`}
                key={idx}
                onChange={(newEntry) => {
                  rowChanged(idx, newEntry);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
