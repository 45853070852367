import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink, useParams, useRouteLoaderData } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils/strings";
import pluralize from "pluralize";
import { FlatDataItem, DataItemDomain } from "@mechination/data_validation";

interface BreadcrumbItem {
  name: string;
  url?: string;
}

function typeToString(type: string | undefined) {
  if (type === "_type") {
    return "Type Definitions";
  }
  if (!type) {
    return "";
  }
  return capitalizeFirstLetter(pluralize(type));
}

export default function Title(props: { sidebarOpen: boolean }) {
  const domain = useRouteLoaderData("domain") as FlatDataItem<DataItemDomain>;
  const { type: typeId, id } = useParams<{ type: string; id: string }>();

  const typeStr = typeToString(typeId);
  let breadcrumbs: BreadcrumbItem[];

  if (!domain) {
    return <div style={{ flexGrow: 1 }} />;
  }

  breadcrumbs = [];
  if (domain) {
    breadcrumbs.push({ name: capitalizeFirstLetter(domain.name), url: `/app/${domain._id}` });
  }
  if (typeId) {
    breadcrumbs.push({ name: typeStr, url: `/app/${domain._id}/types/${typeId}/items` });
  }
  if (id) {
    breadcrumbs.push({ name: id });
  }
  if (breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].url = undefined;
  }

  return (
    <Typography component="div" variant="h6" color={"white"} noWrap sx={{ flexGrow: 1 }}>
      <Breadcrumbs
        aria-label=""
        sx={{
          color: "white",
        }}
      >
        {breadcrumbs.map((bc) =>
          bc.url ? (
            <Link component={RouterLink} key={bc.url} to={bc.url} underline="hover" color="inherit" variant="h6">
              {bc.name}
            </Link>
          ) : (
            <Typography key="tail" variant="h6" fontWeight={200}>
              {bc.name}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Typography>
  );
}
