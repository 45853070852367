import ItemSelector from "../components/ItemSelector";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";

interface ItemRefEditorProps extends SchemaDefinedEditorProps {
  domain?: string;
  types?: string[];
}

export default function ItemRefEditor(props: ItemRefEditorProps) {
  return <ItemSelector value={props.val} name={props.name} types={props.types} onChange={props.onChange} />;
}
