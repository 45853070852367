import * as React from "react";
import { useMemo } from "react";
import Box from "@mui/material/DialogTitle";
import { useLoaderData, useNavigate, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FlatDataItem, DataItemList, DataItemType } from "@mechination/data_validation";
import pluralize from "pluralize";
import ObjectTable from "../field_editors/ObjectTable";
import ItemCards from "../field_editors/ItemCards";
import { SchemaDefinedEditorProps } from "../field_editors/SchemaDefinedEditor";
import { DataAPI } from "@mechination/data_client";
import env from "../env";

export interface ItemListEditorProps extends SchemaDefinedEditorProps {
  onCreate: () => void;
  onDelete: (ids: (number | string)[]) => void;
  onClick: (index: string | number) => void;
  getItemId: (row: any) => number | string;
}

export default function ListItemsInType() {
  const { type: typeId, domainId } = useParams();
  const listItems = useLoaderData() as DataItemList<any>;
  const type = useRouteLoaderData("type") as FlatDataItem<DataItemType>;
  const navigate = useNavigate();
  const [urlSearch] = useSearchParams();
  const [selectedPath, setSelectedPath] = React.useState<string | undefined>();

  const schema = useMemo(() => {
    try {
      return JSON.parse(type.schema!);
    } catch (ex) {
      return { type: "object" };
    }
  }, [type]);

  let view = urlSearch.get("view");

  if (!view) {
    const props = Object.keys(schema.properties ?? {});
    if (props.indexOf("name") !== -1 && props.indexOf("image") !== -1) {
      view = "cards";
    }
  }

  React.useEffect(() => {
    const webSocket = new WebSocket("wss://cqsjvd3d49.execute-api.ap-southeast-2.amazonaws.com/notification/");
    webSocket.onopen = (ev) => {
      console.log("Open");
      webSocket.send("Blah blah blah");
    };
    webSocket.onmessage = (ev) => {
      console.log("Message", ev.data);
    };
    webSocket.onclose = (ev) => {
      console.log("Close");
    };

    return () => {
      webSocket.close();
    };
  });

  if (!type) {
    return <Box>Invalid type</Box>;
  }

  async function deleteItems(ids: (string | number)[]) {
    console.log("Deleting items", ids);
    const api = new DataAPI(env.api.endpoint);
    await Promise.all(ids.map((id) => api.deleteItem(domainId!, type._id, id.toString())));
    navigate(".", { replace: true });
  }

  const path = "#";
  const name = pluralize(type.name);

  const sharedParams: ItemListEditorProps = {
    onDelete: deleteItems,

    onCreate: () => {
      navigate(`/app/${domainId}/types/${typeId}/new`);
    },
    onClick: (id: string | number) => {
      navigate(`/app/${domainId}/types/${typeId}/items/${encodeURIComponent(id)}`);
    },

    getItemId: (r: any) => r._id,
    onChange: (newVal: any) => {},
    modified: false,
    selectedPath,
    onPathSelected: setSelectedPath,
    path,
    name,
    val: listItems,
    errors: [],
    schema,
  };

  if (view === "cards") {
    return <ItemCards {...sharedParams} />;
  }

  // Default view is a table.  Boring!

  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      <ObjectTable description={type.description} {...sharedParams} />
    </div>
  );
}
