import Chip from "@mui/material/Chip";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

interface PersonChipProps {
  sub: string;
  onDelete: () => any;
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

export function PersonChip(props: PersonChipProps) {
  const isGroup = props.sub.startsWith("group:");
  if (props.sub === "*") {
    return <Chip size="small" color="warning" label="any incl. unauthenticated" onDelete={props.onDelete} />;
  }
  return (
    <Chip
      icon={isGroup ? <GroupIcon /> : <PersonIcon />}
      size="small"
      color={props.color}
      onDelete={props.onDelete}
      label={isGroup ? props.sub.substring(6) : props.sub}
    />
  );
}
