import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactNode, useCallback, useEffect, useState } from "react";
import env from "../env";
import { DataAPI, DataHistoryResponse } from "@mechination/data_client";
import { Button, Typography } from "@mui/material";
import Timestamp from "./Timestamp";
import DeltaIcon from "@mui/icons-material/ChangeHistory";
import RemoveIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";

interface ItemHistoryProps {
  type: string;
  domain: string;
  itemId: string;
}

interface DeltaProps {
  delta: any;
}

const api = new DataAPI(env.api.endpoint);

function Delta(props: DeltaProps) {
  // const atomic: IAtomicChange[] = props.delta ? atomizeChangeset(props.delta) : [];
  return (
    <div>
      <pre style={{ fontSize: "60%" }}>{JSON.stringify(props.delta, null, 2)}</pre>
    </div>
  );
}

export default function ItemHistory(props: ItemHistoryProps) {
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<DataHistoryResponse>({ events: [] });

  const loadHistory = useCallback(async () => {
    setHistoryLoading(true);
    try {
      const newHistory = await api.getItemHistory(props.domain, props.type, props.itemId, history.next);
      console.log("History", history);
      setHistory(history.next ? { events: [...history.events, ...newHistory.events], next: newHistory.next } : newHistory);
    } finally {
      setHistoryLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  return (
    <AccordionDetails>
      {historyLoading ? (
        <CircularProgress />
      ) : history.events.length === 0 ? (
        <Typography variant="caption">
          No change history. Item might have been created by process outside of application.
        </Typography>
      ) : (
        <div style={{ maxWidth: "100%", overflow: "hidden" }}>
          <table>
            {history.events.map((e) => {
              let detailCell: ReactNode;
              let icon: ReactNode;
              switch (e.event) {
                case "INSERT":
                  icon = <CreateIcon />;
                  detailCell = <pre style={{ fontSize: "60%" }}>{JSON.stringify(e.item, null, 2)}</pre>;
                  break;

                case "MODIFY":
                  icon = <DeltaIcon />;

                  detailCell = <Delta delta={e.diff} />;
                  break;
                case "REMOVE":
                  icon = <RemoveIcon />;
                  detailCell = undefined;
                  break;
              }

              return (
                <tr>
                  <td>
                    <Timestamp ts={new Date(e.ts)} />
                  </td>
                  <td>{icon}</td>
                  <td>
                    <Typography variant="subtitle2">{e.sub}</Typography>
                  </td>
                  <td>
                    <pre>{detailCell}</pre>
                  </td>
                </tr>
              );
            })}
          </table>

          {historyLoading ? (
            <CircularProgress />
          ) : (
            history.next && <Button onClick={() => loadHistory()}>fetch older events</Button>
          )}
        </div>
      )}
    </AccordionDetails>
  );
}
