import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import * as React from "react";
import { ReactNode, useMemo } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/DialogTitle";
import { AttachmentReference, isFlatDataItem } from "@mechination/data_validation";
import NoImageIcon from "@mui/icons-material/HideImage";

import Link from "@mui/material/Link";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
  selectedGridRowsSelector,
} from "@mui/x-data-grid";
import CreateIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import { DataItemProvider, useDataItemContext } from "../providers/DataItemProvider";
import { attachmentUrl } from "../utils/images";

interface ItemListToolbarProps {
  onCreate: () => {};
  onDelete: (keys: any[]) => {};
  title: string;
  description?: string;
  showQuickFilter?: boolean;
}

const typeMappings: Record<string, Partial<GridColDef<any>>> = {
  "/schemas/image": {
    width: 40,
    renderCell: (cell) => (
      <Avatar sx={{ width: 34, height: 34 }}>
        <DataImage val={cell.value} />
      </Avatar>
    ),
  },

  "/schemas/monetary_value": {
    width: 100,
    valueGetter: (val: any, row) => `$${val.value.toFixed(2)}`,
  },
  "/schemas/measurement": {
    valueGetter: (val: any, row) => `${val.value} ${val.unit}`,
  },

  "/schemas/itemref": {
    width: 140,
    renderCell: (cell) => <Chip variant="outlined" onClick={() => {}} label={cell.value?.id} />,
  },

  boolean: {
    width: 50,
    renderCell: (cell) => {
      return <Checkbox checked={cell.value ?? false} />;
    },
  },

  array: {
    width: 85,
    valueGetter: (val: any, row) => val?.length,
    renderCell: (cell) => {
      return cell.value !== undefined ? <Typography variant="button">{`[${cell.value}]`}</Typography> : <UndefinedValue />;
    },
  },
};

function ItemListToolbar(props: ItemListToolbarProps) {
  const apiRef = useGridApiContext();
  const sel = useGridSelector(apiRef, selectedGridRowsSelector);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const createNew = () => {
    props.onCreate();
  };

  const deleteSelected = () => {
    console.log("Deleting", Array.from(sel.keys()));
    props.onDelete(Array.from(sel.keys()));
    setConfirmDialogOpen(false);
  };

  const closeConfirm = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <GridToolbarContainer sx={{ backgroundColor: "transparent" }}>
      <Dialog open={confirmDialogOpen} onClose={closeConfirm}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Please Confirm that you wish to delete the following items
          <List>
            {Array.from(sel.keys()).map((i) => (
              <ListItem key={i}>{i}</ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteSelected} color="warning">
            Delete
          </Button>
          <Button onClick={closeConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ p: 0 }} display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box sx={{ padding: 0.5, flex: 1 }}>
          {props?.description && <Typography variant="caption">{props.description}</Typography>}
        </Box>

        <IconButton onClick={createNew} title="Create new">
          <CreateIcon />
        </IconButton>
        <IconButton onClick={() => setConfirmDialogOpen(true)} title="Delete selected" disabled={sel.size === 0}>
          <DeleteIcon />
        </IconButton>
        {(props.showQuickFilter ?? true) && <GridToolbarQuickFilter />}
      </Box>
    </GridToolbarContainer>
  );
}

function valueToStr(v: any): string {
  const t = typeof v;

  if (v === undefined) {
    return v;
  }

  if (t === "object") {
    if (Array.isArray(v)) {
      return v.map((v) => valueToStr(v)).join(", ");
    }
    return JSON.stringify(v);
  }
  return v.toString();
}

function DataImage(props: { val: AttachmentReference }) {
  const item = useDataItemContext();
  return item?._domain && props.val?.id ? (
    <img alt="representation of the item" height="100%" src={attachmentUrl(item, props.val.id)} />
  ) : (
    <NoImageIcon />
  );
}

const UndefinedValue = () => (
  <Typography color="GrayText" fontSize={12}>
    &lt;none&gt;
  </Typography>
);

function StrRenderer(props: { value: any }) {
  const { value } = props;
  const rendered = valueToStr(value);

  if (!props.value) {
    return <UndefinedValue />;
  }
  return <Typography>{rendered}</Typography>;
}

function extractColType(schema: any) {
  //   console.log("Checking schema type for ", schema);

  if (!schema) {
    return "_default";
  }

  if (schema.type === "string") {
    if (schema.format) {
      return schema.format;
    }
  }
  if (schema.enum) {
    return "enum";
  }

  if (schema.type === "array") {
    // Different Array types get rendered differently.
    return "array";
  }

  // if (isSchemaObjectDataRef(schema)) {
  //   return "/schemas/itemref";
  // }
  if (schema?.$ref) {
    return schema.$ref;
  }
  if (schema.oneOf) {
    if (schema.oneOf) {
      // If they are all references to other items
      if (!(schema.oneOf as any[]).find((x) => x?.$ref === "/schemas/itemref")) {
        return "/schemas/itemref";
      }
    }
    return "oneof";
  }

  return schema.type ?? "_default";
}

interface ObjectTableProps extends SchemaDefinedEditorProps {
  showQuickFilter?: boolean;
  description?: string;
  hideFooter?: boolean;

  onCreate: () => void;
  onDelete: (ids: (number | string)[]) => void;
  onClick: (index: string | number) => void;
  getItemId: (row: any) => number | string;
}

export default function ObjectTable(props: ObjectTableProps) {
  const columns = useMemo(() => {
    // Infer column names based on the schema
    const schema = props.schema;
    const keys = props.schema?.properties ? Object.keys(schema?.properties) : ["id"];

    const columns = keys.map((k, idx) => {
      const colSchema = schema?.properties && schema.properties[k];
      const colType = extractColType(colSchema);
      const colProps = typeMappings[colType] ?? {};
      // const colPath = `${props.path}/${k}`;

      return {
        field: k,
        headerName: k,
        width: 250,
        ...colProps,
        renderCell: (cell: any) => {
          let r: ReactNode = colProps.renderCell ? colProps.renderCell(cell) : <StrRenderer value={cell.value} />;

          if (isFlatDataItem(cell.row)) {
            r = <DataItemProvider item={cell.row}>{r}</DataItemProvider>;
          }
          return idx === 0 ? <Link onClick={() => props.onClick(cell.id)}>{r}</Link> : r;
        },
      };
    });
    return columns;
  }, [props]);

  return (
    <DataGrid
      rows={props.val}
      columns={columns}
      autoHeight
      // density="compact"
      getRowId={props.getItemId}
      // isCellEditable={(p) => false}
      rowSelectionModel={props.selectedPath}
      onRowClick={(params) => {
        const sel = params.id;
        console.log("DataGrid", props.path, "selection changed", sel);
        props.onPathSelected((Array.isArray(sel) ? sel[0] : sel)?.toString());
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "asc" }],
        },
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
      slots={{
        toolbar: ItemListToolbar as any, // TODO fix typing snafu...
      }}
      hideFooter={props.hideFooter}
      slotProps={{
        toolbar: {
          showQuickFilter: props.showQuickFilter,
          title: props.name,
          description: props.description,
          onCreate: props.onCreate,
          onDelete: () => {
            if (props.selectedPath) {
              props.onDelete([props.selectedPath]);
            }
          },
        },
      }}
      pageSizeOptions={[25, 50, 100]}
      // checkboxSelection
      // disableMultipleRowSelection={false}
      // disableRowSelectionOnClick
    />
  );
}
