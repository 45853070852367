import Icon from "@mui/material/Icon";
import { FlatDataItem } from "@mechination/data_validation";
import LoadingIcon from "@mui/icons-material/Link";
import { attachmentUrl } from "../utils/images";

export default function ObjectIcon(props: { item: FlatDataItem<any> }) {
  return props.item?.image ? (
    <Icon sx={{ verticalAlign: "middle", display: "inline-block" }}>
      <img
        alt="Icon for object"
        className="object_icon"
        src={attachmentUrl(props.item, props.item.image?.id)}
        style={{
          display: "flex",
          width: "inherit",
          height: "inherit",
          color: "inherit",
        }}
      />
    </Icon>
  ) : (
    <LoadingIcon color="inherit" />
  );
}
