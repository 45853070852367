import TextField from "@mui/material/TextField";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";

export default function MeasurementEditor(props: SchemaDefinedEditorProps) {
  return (
    <TextField
      variant="standard"
      sx={{ width: "20em" }}
      name={props.name}
      id={props.name}
      label={props.name}
      helperText={props.description}
      value={props.val ? `${props.val?.value}${props.val?.unit}` : undefined}
    />
  );
}
