import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Input from "@mui/material/Input";

export interface StringArrayEditorProps {
  value: string[];
  onChange: (newVal: string[]) => any;
}

export function StringArrayEditor(props: StringArrayEditorProps) {
  function itemChanged(idx: number, value: string) {
    const newArr = [...props.value];
    newArr[idx] = value;
    props.onChange(newArr);
  }
  function addItem() {
    props.onChange([...props.value, ""]);
  }
  function removeItem(idx: number) {
    const newArr = [...props.value];
    newArr.splice(idx, 1);
    props.onChange(newArr);
  }

  return (
    <Stack>
      {props.value.map((val, idx) => (
        <FormControl variant="standard">
          <Input
            key={idx}
            value={val}
            onChange={(evt) => itemChanged(idx, evt.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => removeItem(idx)}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      ))}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
        <IconButton onClick={addItem} size="small">
          <AddIcon fontSize="small" />
        </IconButton>
      </div>
    </Stack>
  );
}
