import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoImgIcon from "@mui/icons-material/HideImage";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useDataItemContext } from "../providers/DataItemProvider";
import { attachmentUrl } from "../utils/images";
import { ChangeEvent, useState } from "react";
import { Button, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DataAPI } from "@mechination/data_client";
import env from "../env";
import { FlatDataItem } from "@mechination/data_validation";

const api = new DataAPI(env.api.endpoint);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ImageEditor(props: SchemaDefinedEditorProps) {
  const itemContext = useDataItemContext();
  const item = itemContext as FlatDataItem<unknown>;
  const [uploading, setUploading] = useState<string>("");

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files && e.target.files[0];
    console.log("File set to " + f);

    if (f) {
      setUploading("Uploading " + f.name);
      try {
        const id = await api.uploadFile(item._domain, f);
        props.onChange({ key: id });
      } finally {
        setUploading("");
      }
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Snackbar open={Boolean(uploading)} message={uploading} />
      <Typography variant="caption">{props.name}</Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
        {props.val ? (
          <img
            alt="item._id"
            className="checkered"
            style={{ maxHeight: "300px", border: "1px solid black", margin: "5px" }}
            src={attachmentUrl(item, props.val.id)}
          />
        ) : (
          <Alert severity="info" icon={<NoImgIcon />}>
            None
          </Alert>
        )}

        <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
          Upload file
          <VisuallyHiddenInput type="file" onChange={handleFileChange} />
        </Button>
      </Box>
    </Box>
  );
}
