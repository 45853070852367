import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link as RouterLink, useParams, useRouteLoaderData } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";

import { capitalizeFirstLetter } from "../utils/strings";
import Box from "@mui/material/Box";
import pluralize from "pluralize";
import { FlatDataItem, DataItemDomain, DataItemList } from "@mechination/data_validation";
import ObjectIcon from "../components/ObjectIcon";
import { DataAPI } from "@mechination/data_client";
import { compareItems } from "../utils/comparators";
import env from "../env";

export interface SideBarPops {
  title?: string;
}

export default function SideBar(props: SideBarPops) {
  const domain = useRouteLoaderData("domain") as FlatDataItem<DataItemDomain> | undefined;
  const { type: typeId } = useParams<{ type: string; id: string }>();

  const [data, setData] = React.useState<DataItemList<any>>([]);

  React.useEffect(() => {
    const abort = new AbortController();

    async function loadData(signal: AbortSignal) {
      if (domain) {
        try {
          const api = new DataAPI(env.api.endpoint);
          const d = await api.listItems(domain._id, "_type");
          if (!signal.aborted) {
            setData(d);
          }
        } catch (ex: any) {
          console.error(ex);
        }
      } else {
        setData([]);
      }
    }

    loadData(abort.signal);
    return () => {
      abort.abort();
    };
  }, [domain]);

  // Show all types in the domain, except for those that start with a _ (currently only _type)
  const mainListItems = React.useMemo(() => {
    if (!domain) {
      return [];
    }
    return data
      .filter((t: FlatDataItem<unknown>) => !t._id.startsWith("_"))
      .sort(compareItems)
      .map((t: FlatDataItem<unknown>) => {
        const fullName = pluralize(capitalizeFirstLetter(t._id));
        return (
          <Box key={t._id}>
            <ListItemButton
              title={fullName}
              component={RouterLink}
              to={`/app/${domain?._id}/types/${t._id}/items`}
              selected={t._id === typeId}
            >
              <ListItemIcon>
                <ObjectIcon item={t} />
              </ListItemIcon>
              <ListItemText primary={fullName} />
            </ListItemButton>
          </Box>
        );
      });
  }, [data, typeId, domain]);

  const adminTxt = `Edit Types`;
  const secondaryListItems = (
    <ListItemButton title={adminTxt} component={RouterLink} to={`/app/${domain?._id ?? "admin"}/types/_type/items`}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary={adminTxt} />
    </ListItemButton>
  );

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List component="nav" title={props.title} sx={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
        {mainListItems}
      </List>
      <Divider />
      <Box>{secondaryListItems}</Box>
    </Box>
  );
}
