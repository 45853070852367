import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";

export default function BoolEditor(props: SchemaDefinedEditorProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={props.name}
          checked={props.val}
          onChange={(evt) => {
            console.log(evt.target.checked);
            props.onChange(evt.target.checked);
          }}
        />
      }
      label={props.name}
    />
  );
}
