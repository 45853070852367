import { PersonChip } from "./PersonChip";
import { useState } from "react";
import EmailEditor from "./EmailEditor";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export interface PersonListEditorProps {
  value: string[];
  onChange: (newVal: string[]) => any;
  invalidEntries?: number[];
}

export function PersonListEditor(props: PersonListEditorProps) {
  const [newValue, setNewValue] = useState<string>("");
  const [alreadyAddedWarningShown, setAlreadyAddedWarningShown] = useState(false);

  function addItem(newVal: string) {
    if (props.value && props.value.indexOf(newVal) !== -1) {
      // The item is already in the list.... ignore the add
      setAlreadyAddedWarningShown(true);
    } else {
      const newList = [...props.value];
      newList.push(newVal);
      props.onChange(newList);
    }
    setNewValue("");
  }
  function removeItem(idx: number) {
    const newArr = [...props.value];
    newArr.splice(idx, 1);
    props.onChange(newArr);
  }

  const errorSet = new Set(props.invalidEntries ?? []);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "end", gap: "0.33em" }}>
      <Snackbar open={alreadyAddedWarningShown} autoHideDuration={1000} onClose={() => setAlreadyAddedWarningShown(false)}>
        <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
          Identity already present
        </Alert>
      </Snackbar>
      {props.value.map((val, idx) => (
        <PersonChip
          sub={val}
          color={errorSet.has(idx) ? "error" : "default"}
          onDelete={() => {
            removeItem(idx);
          }}
        />
      ))}
      <EmailEditor val={newValue} onChange={setNewValue} placeholder="Add new" onCommit={addItem} />
    </div>
  );
}
