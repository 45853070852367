import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import IamgeIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { FlatDataItem } from "@mechination/data_validation";
import { attachmentUrl } from "../utils/images";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";

import { FixedSizeGrid, GridChildComponentProps } from "react-window";

interface ItemCardProps extends SchemaDefinedEditorProps {
  onClick: () => void;
}

export function ItemCard(props: ItemCardProps) {
  const item = props.val as FlatDataItem<any>;

  return (
    <Card sx={{ margin: 1 }}>
      <CardActionArea onClick={props.onClick}>
        <CardMedia style={{ overflow: "hidden" }}>
          {item.image ? (
            <img
              alt="representation of the item"
              src={attachmentUrl(item, item.image?.id)}
              style={{ width: "100%", height: 140, objectFit: "cover" }}
            />
          ) : (
            <IamgeIcon sx={{ height: 14, zoom: 10 }} />
          )}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" className="twolineellipsis">
            {item.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="twolineellipsis">
            {item.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface ItemCardsProps extends SchemaDefinedEditorProps {
  onCreate: () => void;
  onDelete: (ids: (number | string)[]) => void;
  onClick: (index: string | number) => void;
  getItemId: (row: any) => number | string;
}

function valueToString(val: any, schema: object): string | undefined {
  if (typeof val !== "string") {
    return undefined;
  }
  return val.toLowerCase();
}

function itemMatchesFilter(item: any, schema: any, filterText: string) {
  if (!filterText) {
    return true;
  }

  // For direct linked items, allow for searching by id of the linked item.
  if (schema?.type === "/schemas/itemref") {
    return item?.id;
  }

  return Object.entries(item).filter(([k, val]) => valueToString(val, schema[k])?.includes(filterText)).length > 0;
}

const useRefDimensions = (ref: MutableRefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 2 });
  useEffect(() => {
    if (ref.current) {
      const handleWindowResize = () => {
        const el = ref.current;
        const newDims = { width: Math.trunc(el.offsetWidth), height: Math.trunc(el.offsetHeight) }; // TODO nasty constant
        setDimensions(newDims);
      };
      window.addEventListener("resize", handleWindowResize);
      handleWindowResize();
      return () => window.removeEventListener("resize", handleWindowResize);
    }
  }, [ref]);
  return dimensions;
};

export default function ItemCards(props: ItemCardsProps) {
  const [searchText, setSearchText] = useState<string>("");
  const [committedText, setCommittedText] = useState<string>("");
  const ref = useRef<any>(null);
  const size = useRefDimensions(ref);

  useEffect(() => {
    const tim = setTimeout(() => {
      setCommittedText(searchText.trim().toLowerCase());
    }, 300);

    return () => {
      clearTimeout(tim);
    };
  }, [searchText]);

  const items: any[] = useMemo(() => {
    return [...props.val]
      .filter((i) => itemMatchesFilter(i, props.schema, committedText))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [props.val, committedText, props.schema]);

  function clearText() {
    setSearchText("");
    setCommittedText("");
  }

  const padLeft = 20;
  const width = Math.max(100, size.width - padLeft);
  const cellWidth = 280;

  const numColumns = Math.max(1, Math.trunc(width / cellWidth));
  const rowCount = Math.ceil(items.length / numColumns);

  function renderCell(cellProps: GridChildComponentProps<any>) {
    const idx = cellProps.rowIndex * numColumns + cellProps.columnIndex;
    if (idx >= items.length) {
      return null;
    }
    const item = items[idx];
    const itemId = item ? props.getItemId(item) : idx;

    // console.log("Rendering", idx, itemId);

    return (
      <div style={cellProps.style}>
        <ItemCard key={itemId} {...props} val={item} path={`${props.path}[${idx}]`} onClick={() => props.onClick(itemId)} />
      </div>
    );
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box width="100%" padding={2} display="flex" flexDirection="row">
        <FormControl variant="standard" sx={{ flex: 1 }}>
          <InputLabel htmlFor="searchbox">Search within items</InputLabel>
          <Input
            id="searchbox"
            type="search"
            value={searchText}
            onChange={(evt) => setSearchText(evt.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === "Escape") {
                clearText();
              }
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="start">
                <IconButton size="small" title="clear search text" disabled={!committedText} onClick={clearText}>
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton>
          <AddIcon />
        </IconButton>
        <IconButton disabled>
          <DeleteIcon />
        </IconButton>
      </Box>
      <div ref={ref} style={{ height: 0, flex: 1, paddingLeft: padLeft }}>
        <FixedSizeGrid
          columnCount={numColumns}
          rowCount={rowCount}
          width={width}
          itemData={items}
          height={Math.max(100, size.height)}
          columnWidth={cellWidth}
          rowHeight={320}
        >
          {renderCell}
        </FixedSizeGrid>
      </div>
    </div>
  );
}
