import Paper from "@mui/material/Paper";

import { useRouteLoaderData } from "react-router-dom";
import { FlatDataItem, DataItemDomain } from "@mechination/data_validation";

export default function Index() {
  const domain = useRouteLoaderData("domain") as FlatDataItem<DataItemDomain>;

  if (!domain) {
    return <div>No domain</div>;
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "15em",
        p: 2,
        pb: 8,
      }}
    >
      <p>
        This is the data editor for the {domain._id} domain. This is part of this organisation's broader small data policy, and
        provides an easy way to create and maintain structured data using a consistent look and feel. The platform also provides
        querying and headless CMS capabilities.
      </p>

      {domain?.description ?? (
        <p>
          You can see what other data domains are available, and potentially create your own at the{" "}
          <a href="https://admin.data.mechination.com.au">domain administration site</a>
        </p>
      )}

      <p>
        To interact with data for this domain, please use the data definitions on the left (expanding the menu if needed). If you
        have questions, please contact the <a href={domain?.owner ?? "#"}>domain owner</a>.
      </p>
    </Paper>
  );
}
