import { FlatDataItem } from "@mechination/data_validation";
import { ReactNode, createContext, useContext } from "react";

const DataItemContext = createContext<FlatDataItem<any>>({});

export function useDataItemContext() {
  return useContext(DataItemContext);
}

export function DataItemProvider(props: { item: FlatDataItem<any>; children: ReactNode }) {
  return <DataItemContext.Provider value={props.item}>{props.children}</DataItemContext.Provider>;
}
