import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { useLoaderData, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import Alert from "@mui/material/Alert";
import stringify from "json-stable-stringify";
import { md5 } from "js-md5";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataAPI } from "@mechination/data_client";
import {
  FlatDataItem,
  DataItemDomain,
  DataItemType,
  DataItemValidator,
  stripMeta,
  ValidatorFunction,
  ValidationError,
} from "@mechination/data_validation";
import Snackbar from "@mui/material/Snackbar";
import SchemaDefinedEditor from "../field_editors/SchemaDefinedEditor";
import { DataItemProvider } from "../providers/DataItemProvider";
import Box from "@mui/material/Box";
import env from "../env";
import ItemHistory from "../components/ItemHistory";
import ItemReferences from "../components/ItemReferences";

const validatorFactory = new DataItemValidator(`https://${env.api.endpoint}`);

export interface Marker {
  path: string;
  severity: "error" | "warning" | "info";
  message: Error | string;
}

function convertError(e: ValidationError): Marker {
  const mapped: Marker = {
    path: e.instancePath,
    severity: "error",
    message: e.message?.toString() ?? "No message",
  };

  const reqMatch = e.message?.match(/^must have required property '(?<prop>.*)'/);
  if (reqMatch) {
    mapped.path = `${mapped.path}/${reqMatch.groups!.prop}`;
    mapped.message = "required field";
  }
  return mapped;
}

export default function ItemEditor() {
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [saving, setSaving] = useState<string>("");
  const [validator, setValidator] = useState<ValidatorFunction | undefined>(undefined);
  const [selectedPath, setSelectedPath] = useState<string>("$");
  const { domainId, type: typeId = "invalid", id: itemId = "" } = useParams();
  const domain = useRouteLoaderData("domain") as FlatDataItem<DataItemDomain>;
  const [historyExpanded, setHistoryExpanded] = useState<boolean>(false);
  const [referencesExpanded, setReferencesExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const api = new DataAPI(env.api.endpoint);
  const isNewItem = itemId === "";

  const type = useRouteLoaderData("type") as FlatDataItem<DataItemType>;
  const existingItem: FlatDataItem<any> = useLoaderData();
  const [newItem, setNewItem] = useState(existingItem);

  const parsedSchema = React.useMemo(() => {
    try {
      const ps = JSON.parse(type.schema);
      validatorFactory.createValidator(type).then((v) => {
        setValidator(() => v);
      });
      return ps;
    } catch (ex) {
      console.log(ex);
    }
  }, [type]);

  const resetEditor = React.useCallback(() => {
    setNewItem(existingItem);
  }, [existingItem]);

  React.useEffect(() => {
    resetEditor();
    setHistoryExpanded(false);
    setReferencesExpanded(false);
  }, [existingItem, resetEditor]);

  const valid = React.useMemo(() => {
    try {
      // console.log("using validator", validator);
      if (validator) {
        const validationErrors = validator(stripMeta(newItem), {});
        setMarkers(validationErrors.map(convertError));
        return true;
      } else {
        setMarkers(parsedSchema ? [{ path: "$", severity: "error", message: "invalid schema validator" }] : []);
      }
    } catch (ex: any) {
      setMarkers([
        {
          path: "$",
          severity: "error",
          message: ex,
        },
      ]);
    }
    return false;
  }, [newItem, validator, parsedSchema]);

  const modified = React.useMemo(() => {
    return !existingItem || existingItem._etag !== newItem._etag;
  }, [newItem, existingItem]);

  async function save() {
    // console.log("Saving item", item, text);
    try {
      setMarkers([]);

      const strippedNewItem = stripMeta(newItem);
      if (!existingItem) {
        // Its a new object.
        setSaving("Creating new Item");
        await api.createItem(domain._id, type!, strippedNewItem);
      } else {
        setSaving("Updating item");
        await api.updateItem(domain._id, type._id, itemId, strippedNewItem, {
          condition: { etag: existingItem._etag },
        });
      }

      navigate(`/app/${domainId}/types/${typeId}/items`);
    } catch (ex: any) {
      console.log("Error while saving");
      setMarkers([{ path: "$", severity: "error", message: ex }]);
    } finally {
      setSaving("");
    }
  }

  const updateItem = (path: string[], newNewItem: any) => {
    newNewItem._etag = md5(stringify(stripMeta(newNewItem)));
    setNewItem(newNewItem);
  };

  return (
    <div style={{ overflowY: "auto", overflowX: "hidden", height: 0, flex: 1 }}>
      <Grid container spacing={3} sx={{ padding: 2 }}>
        <Snackbar open={Boolean(saving)} autoHideDuration={6000} onClose={() => setSaving("")} message={saving} />

        <Grid xs={12}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="row">
                <DataItemProvider item={newItem}>
                  <SchemaDefinedEditor
                    schema={parsedSchema ?? { type: "object" }}
                    errors={markers}
                    modified={modified}
                    selectedPath={selectedPath}
                    onPathSelected={(np) => {
                      console.log("Selected path", np);
                      setSelectedPath(np ?? "$");
                    }}
                    name={""}
                    path={"$"}
                    val={newItem}
                    onChange={(newVal) => {
                      try {
                        updateItem(["$"], newVal);
                      } catch (ex) {
                        console.error(ex);
                      }
                    }}
                  />
                </DataItemProvider>
              </Box>
            </CardContent>
            <CardActions>
              <Button size="small" color="success" disabled={!modified || !valid} onClick={save}>
                Save
              </Button>
              {existingItem && (
                <Button size="small" color="warning" disabled={!modified} onClick={resetEditor}>
                  Reset
                </Button>
              )}
              <Button size="small" color="error" onClick={() => navigate(`/app/${domainId}/types/${typeId}/items`)}>
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {markers.map((m, idx) => (
          <Grid xs={12} key={`marker${idx}`}>
            <Alert variant="outlined" severity={m.severity}>
              {m.path} - {m.message.toString()}
            </Alert>
          </Grid>
        ))}

        {!isNewItem && (
          <>
            <Grid xs={12}>
              <Accordion expanded={referencesExpanded} onChange={(evt, isExpanded) => setReferencesExpanded(isExpanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Items referencing this item</AccordionSummary>
                {referencesExpanded && <ItemReferences type={typeId} domain={domainId!} itemId={itemId} />}
              </Accordion>
            </Grid>

            <Grid xs={12}>
              <Accordion expanded={historyExpanded} onChange={(evt, isExpanded) => setHistoryExpanded(isExpanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Change History</AccordionSummary>
                {historyExpanded && <ItemHistory type={typeId} domain={domainId!} itemId={itemId} />}
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
