export interface CreateResult {
  id: string;
  creationDate: Date;
  etag: string;
}

/**
 * When updating an item, we return its modification date and etag, or null if the change was not made.
 */
export type UpdateResult = {
  lastModification: Date;
  etag: string;
} | null;

export interface ETagCondition {
  etag: string;
}

export interface ChangedCondition {
  ifChanged: true;
}

export interface NotExistsCondition {
  exists: false;
}

export type UpdateCondition = ETagCondition | ChangedCondition | NotExistsCondition | undefined;

export interface UpdateOptions {
  condition?: UpdateCondition;
}

export type DeleteCondition = ETagCondition | undefined;

export function isEtagCondition(x: UpdateCondition): x is ETagCondition {
  return (x as any)?.etag;
}

export function isChangedCondition(x: UpdateCondition): x is ChangedCondition {
  return (x as any)?.ifChanged === true;
}

export function isNotExistsCondition(x: UpdateCondition): x is NotExistsCondition {
  return (x as any)?.exists === false;
}
