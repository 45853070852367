import Stack from "@mui/material/Stack";
import SchemaDefinedEditor, { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Chip } from "@mui/material";
import { ReactNode } from "react";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";

export default function ObjectEditor(props: SchemaDefinedEditorProps) {
  const { schema: parsedSchema, val: obj } = props;

  const propChanged = (newValue: string, prop: string) => {
    props.onChange({ ...props.val, [prop]: newValue });
  };

  function removeProp(propName: string, path: string) {
    const newItem = { ...obj };
    delete newItem[propName];
    props.onChange(newItem);
  }

  const editors = Object.entries(parsedSchema.properties ?? {}).map(([propName, propsSchema]) => {
    const path = `${props.path}.${propName}`;

    const optional = !parsedSchema.required || parsedSchema.required.indexOf(propName) === -1;

    if (optional && (!obj || obj[propName] === undefined)) {
      return undefined;
    }

    const propVal = obj ? obj[propName] : undefined;

    let ed: ReactNode = (
      <SchemaDefinedEditor
        schema={propsSchema}
        path={path}
        errors={props.errors}
        modified={props.modified}
        selectedPath={props.selectedPath}
        onPathSelected={props.onPathSelected}
        key={path}
        name={propName}
        val={propVal}
        onChange={(newVal: any) => propChanged(newVal, propName)}
      />
    );

    if (optional && propVal !== undefined) {
      ed = (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {ed}
          <Button
            color="warning"
            onClick={() => removeProp(propName, path)}
            startIcon={<RemoveIcon />}
            size="small"
            sx={{ alignSelf: "flex-start" }}
          >
            Remove optional property "{propName}"
          </Button>
        </div>
      );
    }

    return ed;
  });

  function addProperty(propName: string) {
    console.log("adding property", propName, parsedSchema);
    const propSchema = parsedSchema.properties[propName];
    const propSchemaType = propSchema?.type;

    let val: any;
    if (propSchemaType === "object" || propSchema?.$ref) {
      val = {};
    } else if (propSchemaType === "array") {
      val = [];
    } else if (propSchemaType === "number" || propSchemaType === "integer") {
      val = 0;
    } else if (propSchemaType === "boolean") {
      val = false;
    } else if (propSchemaType === "null") {
      val = null;
    } else {
      val = "";
    }
    // TODO deal with oneOf/allOf
    propChanged(val, propName);
  }

  const missingOptionalProps = parsedSchema.required
    ? Object.keys(parsedSchema.properties).filter(
        (prop) => parsedSchema.required.indexOf(props) === -1 && (!obj || obj[prop] === undefined)
      )
    : [];

  const newOptionalPropsButton = missingOptionalProps.length > 0 && (
    <div style={{ display: "flex", flexDirection: "row", gap: "0.25em" }}>
      <Typography variant="h6" fontSize={"80%"}>
        optional properties
      </Typography>
      {missingOptionalProps.map((f) => (
        <Chip
          icon={<AddIcon />}
          key={f}
          label={f}
          size="small"
          onClick={() => {
            addProperty(f);
          }}
        />
      ))}
    </div>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {props.name}
      </Typography>
      <Stack spacing={3}>
        {editors}
        {newOptionalPropsButton}
      </Stack>
    </Box>
  );
}
