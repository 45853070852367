import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useMemo } from "react";

interface EnumEditorProps extends SchemaDefinedEditorProps {}

export default function EnumEditor(props: EnumEditorProps) {
  const { val, errors, path, schema } = props;

  const error: string | undefined = useMemo(() => errors.find((e) => e.path === path)?.message?.toString(), [errors, path]);

  return (
    <FormControl>
      <InputLabel>{props.name}</InputLabel>
      <Select
        variant="standard"
        onChange={(evt) => props.onChange(evt.target.value)}
        name={props.name}
        id={props.name}
        error={error !== undefined}
        label={props.name}
        value={val}
      >
        {schema.enum.map((v: string) => (
          <MenuItem value={v}>{v}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
