import { FlatDataItem } from "@mechination/data_validation";

export function compareItems(a: FlatDataItem<any>, b: FlatDataItem<any>) {
  let v = (a.display_order ?? 0) - (b.display_order ?? 0);
  if (v !== 0) {
    return v;
  }
  // If the items have names, compare them.
  if (a.name) {
    v = a.name.localeCompare(b.name);
    if (v !== 0) {
      return v;
    }
  }
  return a.id.localeCompare(b.id);
}
