import {
  DataItemType,
  DataItemList,
  DataItemDomain,
  FlatDataItem,
  isEtagCondition,
  isChangedCondition,
  isNotExistsCondition,
  DataItemEvent,
  CreateResult,
  DeleteCondition,
  UpdateOptions,
  UpdateResult,
} from "@mechination/data_validation";
import stringify from "json-stable-stringify";
import md5 from "md5";

export type AuthTokenFetcher = () => string | Promise<string>;

export class HttpError extends Error {
  statusCode: number;
  body: any;

  constructor(statusCode: number, statusText: string, body: any) {
    super(statusText);
    this.statusCode = statusCode;
    this.body = body;

    // This is here so that instanceof works in Typescript.
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  toString() {
    return `non-OK response: ${this.statusCode}: ${this.message}`;
  }
}

export interface DataHistoryResponse {
  events: DataItemEvent[];
  next?: string;
}

/**
 * In HTTP headers, etags have quotes around them... not sure why... Anyway, this strips them off.
 * @param etag
 * @returns
 */
function parseEtag(etag: string): string {
  if (etag.startsWith('"')) {
    return etag.substring(1, etag.length - 1);
  }
  return etag;
}

export class DataAPI {
  auth: AuthTokenFetcher | null;
  hostname: string;

  constructor(hostname: string, auth: AuthTokenFetcher | null = null) {
    this.auth = auth;
    if (!hostname) {
      throw new Error("DomainSuffix must be specified");
    }
    this.hostname = hostname;
  }

  private async doFetch(domain: string, path: string, opts: RequestInit = {}): Promise<Response> {
    const url = `https://${this.hostname}/data/${domain}/${path}`;

    const reqOpts: RequestInit = {
      ...opts,
      mode: "cors",
      credentials: "include",
      cache: "no-cache",
    };

    if (this.auth) {
      if (!reqOpts.headers) {
        reqOpts.headers = {};
      }
      (reqOpts.headers as Record<string, string>).authorization = `Bearer ${await this.auth()}`;
    }
    const res = await fetch(url, reqOpts);

    // We consider 304 not modified a special form of ok, as it is the response from an if-none-match when no update occurred.
    if (!res.ok) {
      const txt = res.headers.get("content-type") == "application/json" ? await res.json() : await res.text();
      throw new HttpError(res.status, res.statusText, txt);
    }
    return res;
  }

  async createItem<X>(domain: string, type: FlatDataItem<DataItemType>, val: X): Promise<CreateResult> {
    const res = await this.doFetch(domain, encodeURIComponent(type._id), {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: stringify(val),
    });
    const content = await res.json();
    return {
      id: content.created_id,
      creationDate: new Date(res.headers.get("last-modified")!),
      etag: content.etag,
    };
  }

  async updateItem<X>(domain: string, type: string, id: string, data: X, options?: UpdateOptions): Promise<UpdateResult> {
    const headers = {
      "content-type": "application/json",
    };

    if (isEtagCondition(options?.condition)) {
      headers["if-match"] = `"${options.condition.etag}"`;
    } else if (isChangedCondition(options?.condition)) {
      headers["if-none-match"] = `"${md5(stringify(data))}"`;
    } else if (isNotExistsCondition(options?.condition)) {
      throw new Error("Not Implemented");
    }
    const ser = stringify(data);

    let url = `${encodeURIComponent(type)}/${encodeURIComponent(id)}`;
    try {
      const res = await this.doFetch(domain, url, {
        method: "PUT",
        headers,
        body: ser,
      });
      return {
        etag: parseEtag(res.headers.get("etag")!),
        lastModification: new Date(res.headers.get("last-modified")!),
      };
    } catch (ex: any) {
      if (ex.statusCode == 304 || ex.statusCode == 412) {
        return null;
      }
      throw ex;
    }
  }

  async getItem<X>(domain: string, type: string, id: string): Promise<FlatDataItem<X>> {
    const res = await this.doFetch(domain, `${encodeURIComponent(type)}/${encodeURIComponent(id)}`);
    const item: X = (await res.json()) as X;

    const modifiedHeader = res.headers.get("last-modified")!;
    const etagHeader = parseEtag(res.headers.get("etag")!);

    return {
      _id: id,
      _domain: domain,
      _type: type,
      _last_updater: res.headers.get("x-last-updater") ?? "unknown",
      _modified_at: new Date(modifiedHeader),
      _etag: etagHeader,
      ...item,
    };
  }

  async getItemHistory(domain: string, type: string, id: string, start?: string): Promise<DataHistoryResponse> {
    let url = `${encodeURIComponent(type)}/${encodeURIComponent(id)}/history`;
    if (start) {
      url += `?start=${encodeURIComponent(start)}`;
    }
    const res = await this.doFetch(domain, url);
    const events: DataHistoryResponse = await res.json();

    return events;
  }

  async getItemReferences(domain: string, type: string, id: string, start?: string): Promise<FlatDataItem<unknown>[]> {
    let url = `${encodeURIComponent(type)}/${encodeURIComponent(id)}/links`;
    if (start) {
      url += `?start=${encodeURIComponent(start)}`;
    }
    const res = await this.doFetch(domain, url);
    const items: FlatDataItem<unknown>[] = (await res.json()).items;
    return items;
  }

  async getDomain(domainId: string): Promise<FlatDataItem<DataItemDomain>> {
    return await this.getItem("admin", "domain", domainId);
  }

  async getType(domain: string, type: string): Promise<FlatDataItem<DataItemType>> {
    return await this.getItem(domain, "_type", type);
  }

  async listItems<X>(domain: string, type: string): Promise<DataItemList<X>> {
    return await (await this.doFetch(domain, encodeURIComponent(type))).json();
  }

  async uploadFile(domain: string, f: File): Promise<string> {
    const url = `https://${this.hostname}/data/${domain}/_att`;
    const reqOpts: RequestInit = {
      method: "POST",
      mode: "cors",
      credentials: "include",
      cache: "no-cache",
      body: f,
      headers: {
        "content-type": f.type,
      },
    };

    if (this.auth) {
      if (!reqOpts.headers) {
        reqOpts.headers = {};
      }
      (reqOpts.headers as Record<string, string>).authorization = `Bearer ${await this.auth()}`;
    }
    const res = await fetch(url, reqOpts);

    // We consider 304 not modified a special form of ok, as it is the response from an if-none-match when no update occurred.
    if (!res.ok) {
      const txt = await res.text();
      throw new HttpError(res.status, res.statusText, txt);
    }
    const j = await res.json();
    return j.id;
  }

  async deleteItem<X>(domain: string, type: string, id: string, condition?: DeleteCondition) {
    await this.doFetch(domain, `${encodeURIComponent(type)}/${encodeURIComponent(id)}`, {
      method: "DELETE",
      headers: condition
        ? {
            "if-match": `"${condition.etag}"`,
          }
        : undefined,
    });
  }

  // async getItemAttachmentMetadata(domain: string, type: string, id: string) {
  //   const res = await this.doFetch(domain, `${encodeURIComponent(type)}/${encodeURIComponent(id)}/attach`);
  //   return await res.json();
  // }

  async headAttachment(domain: string, attachmentId: string) {
    const res = await this.doFetch(domain, `_att/${encodeURIComponent(attachmentId)}`, { method: "HEAD" });
    return {
      etag: parseEtag(res.headers.get("etag")!),
      modified_at: new Date(res.headers.get("last-modified")),
      contentType: res.headers.get("content-type") ?? "application/octet-stream",
      length: parseInt(res.headers.get("content-length")!),
    };
  }
}
